import { Component, Show, mergeProps, createMemo } from "solid-js";
import { BannerFields } from "./Banner.block";
import placeholder from "~/assets/placeholder.png";
import { builderImage } from "~/utils/builder";
import { Img } from "~/components/image";

const Banner: Component<BannerFields> = (inProps) => {
  const props = mergeProps(
    {
      image: placeholder,
      bgPos: "Center",
    },
    inProps
  );
  const filter = createMemo(() => {
    switch (props.filterBg) {
      case "None":
        return "";
      case "Darken":
        return "backdrop-brightness-75";
      case "Lighten":
        return "backdrop-brightness-125";
    }
  });
  const bgPosition = createMemo(() => {
    switch (props.bgPos) {
      case "Center":
        return "bg-center";
      case "Top":
        return "bg-top";
      case "Bottom":
        return "bg-bottom";
      case "Left":
        return "bg-left";
      case "Right":
        return "bg-right";
      case "Top-Right":
        return "bg-right-top";
      case "Top-Left":
        return "bg-left-top";
      case "Bottom-Right":
        return "bg-right-bottom";
      case "Bottom-Left":
        return "bg-left-bottom";
    }
  });
  const copyWidth = createMemo(() => {
    switch (props.copyWidth) {
      case "None":
        return "";
      case "Small":
        return "max-w-sm";
      case "Medium":
        return "max-w-md";
      case "Large":
        return "max-w-2xl";
    }
  });
  return (
    <div class="w-full aspect-1/1 sm:aspect-7/3 flex justify-center items-center">
      <div class="absolute -z-20 hidden">
        <Img
          src={builderImage({
            src: props.image,
            width: 2000,
            height: 500,
            format: "web,",
          })}
          alt={props.imageAlt ?? ""}
          width={2000}
          height={500}
          layout="constrained"
        />
      </div>
      <div
        class={`${
          props.layout === "Fullbleed"
            ? "w-full"
            : "md:basis-2/3 grow lg:shrink-0"
        } w-full h-full ${bgPosition()} bg-cover bg-no-repeat flex justify-center items-center relative`}
        style={{
          "background-image": `url('${builderImage({
            src: props.image,
            width: 2000,
            height: 857,
            fit: "cover",
            quality: 80,
          })}')`,
        }}
      >
        <div class={`w-full h-full absolute ${filter()}`} />
        <Show when={props.layout === "Fullbleed"}>
          <div
            class={`${
              props.textColor === "White" ? "text-white" : "text-black"
            } flex flex-col gap-4 justify-center items-center text-center z-10 px-3 ${copyWidth()}`}
          >
            <Show when={props.headerType === "Image"}>
              <div class="max-w-xs">
                <Img
                  src={builderImage({
                    src: props.imageHeader as string,
                    width: 320,
                    height: 320,
                    format: "web,",
                  })}
                  alt={props.imageHeaderAlt ?? ""}
                  layout="fullWidth"
                />
              </div>
            </Show>
            <Show when={props.headerType === "Text"}>
              <Show when={props.preHeader}>
                <h3 class="md:text-lg">{props.preHeader}</h3>
              </Show>
              <Show when={props.header}>
                <h2 class="text-2xl md:text-3xl font-bold">{props.header}</h2>
              </Show>
              <Show when={props.subHeader}>
                <h3 class="md:text-lg">{props.subHeader}</h3>
              </Show>
            </Show>
          </div>
        </Show>
      </div>
      <Show when={props.layout === "Two-Thirds"}>
        <div
          class={`${props.textColor === "White" ? "text-white" : "text-black"} 
          basis-1/3
          bg-roma-grey 
          text-center 
          flex 
          flex-col 
          gap-3
          sm:gap-5 
          justify-center 
          items-center 

          sm:h-full 
          
          absolute 
          sm:static 
          
          bg-opacity-50 
          sm:bg-opacity-100 
          
          p-3
          m-10
          sm:m-0
          `}
        >
          <Show when={props.headerType === "Image"}>
            <div class="max-w-xs">
              <Img
                src={builderImage({
                  src: props.imageHeader as string,
                  width: 1000,
                  height: 500,
                  format: "web,",
                })}
                alt={props.imageHeaderAlt ?? ""}
                width={1000}
                height={500}
                layout="constrained"
              />
            </div>
          </Show>
          <Show when={props.headerType === "Text"}>
            <div class="max-w-xl space-y-5">
              <Show when={props.preHeader}>
                <h3 class=" text-xs font-medium px-5">{props.preHeader}</h3>
              </Show>
              <Show when={props.header}>
                <h2 class="text-2xl md:text-3xl font-bold px-5">
                  {props.header}
                </h2>
              </Show>
              <Show when={props.subHeader}>
                <h3 class="text-xs px-5 font-medium">{props.subHeader}</h3>
              </Show>
            </div>
          </Show>
        </div>
      </Show>
    </div>
  );
};

export default Banner;
