import { Component, Show } from "solid-js";
import { A } from "@solidjs/router";
import { createDate } from "@solid-primitives/date";
import { StoryThumbnailFields } from "./StoryThumbnail.block";
import { builderImage } from "~/utils/builder";
import { Img } from "~/components/image";

const StoryThumbnail: Component<
  Omit<StoryThumbnailFields, "date"> & { date: Date }
> = (props) => {
  const truncDesc = (str: string, n: number) => {
    return str.length > n ? str.substring(0, n - 1) + "..." : str;
  };
  const [date] = createDate(props.date!);
  return (
    <div class="max-w-lg flex flex-col gap-4 ">
      <A href={props.href!} class="cursor-pointer">
        <div class="w-full aspect-4/3 mb-4 overflow-hidden">
          <Img
            src={builderImage({
              src: props.image as string,
              width: 550,
              quality: 80,
            })}
            alt={props.bannerAlt ?? ""}
            width={550}
            aspectRatio={1.33}
            class="hover:scale-110 hover:brightness-90 transition-all duration-500"
          />
        </div>
      </A>
      <h4 class="text-2xl font-bold">{props.title}</h4>
      <p class="text-sm">{truncDesc(props.description!, 150)}</p>
      <div class="flex flex-row gap-1 items-center text-xs">
        <Show when={props.category}>
          <span>{props.category}</span>
          <span>•</span>
        </Show>
        <p>
          {date()
            ?.toLocaleDateString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })
            .replace(",", "")}
        </p>
      </div>
    </div>
  );
};

export default StoryThumbnail;
